@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes fadeDentro {
    from {opacity:0;
    }
    to {opacity:100;
        }
  }

@keyframes fadeDentro {
    from {opacity:0;
    }
    to {opacity:100;
        }
  }

.front{
    display: grid;
    grid-template-rows: 7vh 93vh;
    width: 99vw;
    height: 99vh;
}

.fundo{
    

    background: 
    -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(12, 20, 88, 0.459)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(11, 60, 92, 0.329))),url(/static/media/BrunoSky3F.49d77ca1.jpg) no-repeat center center fixed;
    
    
    background-size: cover;  
}

.fundo2{

    
    background: 
    -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(12, 20, 88, 0.459)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(11, 60, 92, 0.329))),url(/static/media/bruno3N.897858dc.jpg) no-repeat center center fixed;
    
    
    background-size: cover;  
}

.buttonManage{
    grid-template-rows: 1fr 4fr 1fr;
    padding-top: 8vh;
    align-items: center;
    justify-content: start;
}




.coverAndTittle{
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    margin-left: 10vw;
    margin-right: 10vw;
}
@media only screen and (min-width: 921px) {
.beginingBan{
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.banRight{
    background-color: rgba(11, 60, 92, 0.329);
    font-family: 'Anton', sans-serif;
    color: rgba(255, 255, 255, 0.863);
    text-align: left;
    -webkit-animation-name: fadeDentro;
            animation-name: fadeDentro;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    
}
.textButton{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: left;
    margin: auto;
}

.espaco{
    padding:calc(2.5px + 1vw);
}
}

@media only screen and (max-width: 1115px) {
    .beginingBan{
        display: grid;
        grid-template-columns: 3fr 1fr;
    }
    
    .banRight{
        background-color: rgba(11, 60, 92, 0.329);
        font-family: 'Anton', sans-serif;
        color: rgba(255, 255, 255, 0.863);
        text-align: left;
        -webkit-animation-name: fadeDentro;
                animation-name: fadeDentro;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        max-height: 25vh;
        
    }
    .textButton{
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        justify-content: left;
        margin: auto;
    }

    .espaco{
        padding:calc(2.5px + 1vw);
    }

}
.foraGrid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@-webkit-keyframes fadeDentro {
    from {opacity:0;
    }
    to {opacity:100;
        }
  }

@keyframes fadeDentro {
    from {opacity:0;
    }
    to {opacity:100;
        }
  }
@media only screen and (min-width: 1115px) {
.sobreMim{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /*grid-template-rows: 60vh;*/
    border: 10px;
    background-color: rgb(255, 255, 255);
}
}

@media only screen and (max-width: 1115px) {
    .sobreMim{
        display: grid;
        grid-template-rows: 130vh;
        border: 10px;
        background-color: rgb(255, 255, 255);
        align-items: center;
        justify-items: center;
    }
}

@media only screen and (min-width: 1115px) {
    .conteudo{
        display: grid;
        padding: 5vh;
        border: 4px;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    
    }
}

@media only screen and (max-width: 1115px) {
    .conteudo{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 5vh;
        border: 4px;
        /*grid-template-rows: 50vh 1fr;*/
        align-items: center;
        justify-items: center;
    
    }
}

.eu{
    max-width: 400px;
    max-height: 400px;
    border: 1px solid rgba(202, 202, 202, 0.801);
    padding: 5px;
    border-radius: 2px;
    
}



.infoLayout{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.imgMax{
    max-width: 40px;
    max-height: 40px;
    padding: 5px;
    opacity: 0.7;
}

.imgMax:hover{

    opacity: 0.9;
    transition: 0.5s;
    
}



/*{
    display: grid;
    grid-template-rows: 70vh 50vh;
    max-width: 95vw;
    max-height: 95vh;
    width: 95vw;
    height: 95vh
}*/

.videoLayOut{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    
}

.espacoEntre{
    padding:calc(2.5px + 1vw);
    
}


.galeria3{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 4vh;
    width: 100%;
}

.textoOrdem{
    padding-left:calc(15px + 2vw);
    padding-right:calc(15px + 2vw);
    width: 99vw;
    justify-content: center;
    align-content: center;
    text-align: justify;
    
}

.texto{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 99vw;
}

.imgSize{
        max-width: 40px;
        max-height: 40px;
        
    
}
.webMain{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 4vh;
    justify-content: center;
    text-align: justify;
    
}

.cardLay{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.miner{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px;
    
}
.toDoForma{
    /*display: grid;
    grid-template-rows: 5vh 95vh;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 4vh;
    width: auto;
    justify-items: center;

    
    background-color:  rgb(255, 255, 255);
}

.toDo{
    display: flex;
    padding: 0px;
    border:0px;
    flex-flow: row wrap;
    justify-content: center;
    width: 99vw;
    flex-basis: auto;
    text-align: center;
    align-items: center;
    margin-bottom: 0px;
    background: linear-gradient(180deg, rgba(63, 116, 177, 0.589) 0%, rgb(255, 255, 255) 2%, rgb(255, 255, 255) 98%, rgba(63, 116, 177, 0.589) 100%);
    
    
}

.menuBox{
    width: 33vw;
    align-content: center;
    text-align: center;
    text-decoration: none;
}


.menuBox:hover{
    background: rgba(182, 182, 182, 0.623);
    
    transition: 1s;
    
    
}



.galeria{
    display: inline-flexbox;
    max-width: 95vw;
    width: 95vw;
    
    padding-top: 0vh;
    padding-bottom: 0vh;
    justify-content: center;
    padding-left: 5vw;
    
}
.madaMain{
    display: flex;
    flex-wrap: wrap;
    min-height: 70vh;
    max-height: 200vh;
    background-image: url(/static/media/madagascarWeb2.654db5c8.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-animation: slide 60s linear infinite;
            animation: slide 60s linear infinite;
    width: 100vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    padding: 1vw;
    padding-left: 9vw;
    padding-right: 9vw;
    
}

.infoDisplay{
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.26);
    min-width: 40vw;
    max-width: 300px;
    
    
    
}

/*.infoDisplay2{
    display: flex;
    flex-wrap: wrap;
    min-width: 40vw;
    max-width: 300px;
    max-height: 120vh;
    padding-left: 3vw;
    
    
}*/
.infoDisplay2{
    align-items: center;
    justify-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: 40vw;
    max-width: 300px;
    max-height: 120vh;
    padding-left: 3vw;
    
    
}

.textDisplay{
    margin: 20px;

}

.texto2{
    color: seashell;
}

.eu2{
    max-width: 266.66px;
    max-height: 200px;
    padding: 5px;
    border-radius: 2px;
    
}



.footer{
    display: flex;
    flex-flow: row wrap;
    height: 10vh;
    max-width: 100vw;
    align-items: center;
    padding-left: 10vw;
    
}


.appLay{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
